import {createGlobalStyle} from 'styled-components';
import Typography from '../constants/typography';

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'TT Commons';
    font-weight: normal;
    font-style: normal;
    src: local('TT Commons'),
      url('/fonts/ttcommons-regular.woff2') format('woff2'),
      url('/fonts/ttcommons-regular.woff') format('woff'),
      url('/fonts/ttcommons-regular.ttf') format('truetype'),
      url('/fonts/ttcommons-regular.eot') format('embedded-opentype');
    unicode-range: U+000-5FF;
  }

  @font-face {
    font-family: 'TT Commons';
    font-weight: bold;
    font-style: normal;
    src: local('TT Commons Bold'),
      url('/fonts/ttcommons-bold.woff2') format('woff2'),
      url('/fonts/ttcommons-bold.woff') format('woff'),
      url('/fonts/ttcommons-bold.ttf') format('truetype'),
      url('/fonts/ttcommons-bold.eot') format('embedded-opentype');
    unicode-range: U+000-5FF;
  }

  @font-face {
    font-family: 'TT Commons';
    font-weight: normal;
    font-style: italic;
    src: local('TT Commons Italic'),
      url('/fonts/ttcommons-italic.woff2') format('woff2'),
      url('/fonts/ttcommons-italic.woff') format('woff'),
      url('/fonts/ttcommons-italic.ttf') format('truetype'),
      url('/fonts/ttcommons-italic.eot') format('embedded-opentype');
    unicode-range: U+000-5FF;
  }

  @font-face {
    font-family: 'TT Commons';
    font-weight: bold;
    font-style: italic;
    src: local('TT Commons Bold Italic'),
      url('/fonts/ttcommons-bold-italic.woff2') format('woff2'),
      url('/fonts/ttcommons-bold-italic.woff') format('woff'),
      url('/fonts/ttcommons-bold-italic.ttf') format('truetype'),
      url('/fonts/ttcommons-bold-italic.eot') format('embedded-opentype');
  }

  * {
    padding: 0;
    margin: 0;
  }

  html,
  body {
    font-family: 'TT Commons', 'Helvetica Neue', helvetica, arial, sans-serif;
    font-display: swap;
    font-size: 14px;
    color: #333;
    overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  }

  button {
    font-family: 'TT Commons', 'Helvetica Heue', helvetica, arial, sans-serif;
  }

  a {
    cursor: pointer;
    color: #333;
    font-size: 1.3em;
    text-decoration: none;
    border-bottom: 3px solid #fff;
    padding-bottom: 4px;
    transition: border-bottom-color 0.5s cubic-bezier(0, 0, 0.3, 1);
  }

  a:hover {
    border-bottom: 3px solid #27a085;
  }

  a.external_nav_link {
    font-size: 1.2rem;
    border-top: 2px solid #fff;
    margin-top: 0.5em;
  }

  a.mobile_external_nav_link {
    font-size: 1.2rem;
    margin: 0 1em;
    border-bottom: 0px;
    opacity: 0;
    transform: translateX(-1em);
    transition: opacity 0.3s cubic-bezier(0.1, 0.1, 0.3, 1) 0.1s,
      transform 0.3s cubic-bezier(0.1, 0.1, 0.3, 1) 0.1s;
    padding: 1em 0em;
  }

  a.mobile_external_nav_link.show {
    opacity: 1;
    transform: translateX(0.8em);
  }

  a.mobile_external_nav_link:hover {
    border-bottom: 0px;
  }

  a.footer_link {
    font-size: 1.2em;
  }

  a.external_link {
    font-size: 1em;
    margin-top: 1em;
    display: inline-block;
  }

  a.plans_external_link {
    font-size: 1.3rem;
    line-height: 1.5;
    margin-top: 1em;
    margin-right: 2rem;
    margin-top:0rem;
    margin-bottom:0;
    color: #777777;
  }

  a.licence_external_link {
    margin-top: 0;
    margin-bottom:0;
    font-weight: bold;
  }

  .recurly-element-card {
    border-radius: 0px;
    height: 47px;
    margin-top: 0px;
  }

  .recurly-element-focus {
    border: 1px solid #27a085;
  }

  .recurly-three-d-secure-action{
    height:500px;
  }

  @media (max-width: 960px) {
    a.external_nav_link {
      display: none;
    }

    a.mobile_external_nav_link {
      display: block;
    }
  }
  
  .blog-header-title {
    font-size: ${Typography.title.desktop.fontSize};
    @media (max-width: 800px) {
      font-size: ${Typography.title.mobile.fontSize};
    }
  }
`;
