import React, {createContext, FunctionComponent} from 'react';
import {useProvideAuth} from './auth.hooks';
import {AuthContextState} from './auth.types';

export const AuthContext = createContext({
  user: {},
} as AuthContextState);

export const AuthProvider: FunctionComponent = ({children}) => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
