import {
  Configuration,
  OAuthTokenResponse,
  tokenRefresher,
} from '@focusrite-novation/ampify-api';
import {BaseAPI} from '@focusrite-novation/ampify-api/dist/generated/base';
import {key as storageKey} from './storage';
import store from 'store';
import {AxiosError} from 'axios';

export const createAuthedApi = <T extends BaseAPI>(
  apiType: {new (config: Configuration): T},
  basePath: string
): T => {
  const config: Configuration = {
    isJsonMime: () => true,
    basePath,
  };

  const getTokenAccessor = (): OAuthTokenResponse => {
    const authCreds: OAuthTokenResponse = store.get(storageKey);

    if (!authCreds?.access_token || !authCreds.refresh_token) {
      throw new MissingCredentialsError();
    }

    return authCreds;
  };

  config.apiKey = tokenRefresher({
    clientId: 'ampify',
    tokenAccessor: getTokenAccessor,
    tokenSetter: (tokenResponse) => store.set(storageKey, tokenResponse),
    scope: 'email profile openid',
    config,
  });
  return new apiType(config);
};

export const createApi = <T extends BaseAPI>(
  apiType: {new (config: Configuration): T},
  basePath: string
): T => {
  const config: Configuration = {
    isJsonMime: () => true,
    basePath,
  };
  return new apiType(config);
};

export interface ApiError {
  message?: string;
  errorCode?: string;
  statusCode?: number;
}

export const parseApiError = (error: Error): ApiError => {
  const axiosError = error as AxiosError;
  const message = axiosError?.response?.data?.message;
  const errorCode = axiosError?.response?.data?.errorCode;
  return {
    statusCode: axiosError?.response?.status,
    message,
    errorCode,
  };
};

export class MissingCredentialsError extends Error {
  constructor() {
    super('MissingCredentialsError: unable to find user credentials');

    this.name = 'MissingCredentialsError';
  }
}
