// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-ampify-sounds-index-tsx": () => import("./../../../src/pages/ampify-sounds/index.tsx" /* webpackChunkName: "component---src-pages-ampify-sounds-index-tsx" */),
  "component---src-pages-ampify-sounds-lee-malcolm-tsx": () => import("./../../../src/pages/ampify-sounds/lee-malcolm.tsx" /* webpackChunkName: "component---src-pages-ampify-sounds-lee-malcolm-tsx" */),
  "component---src-pages-ampify-studio-tsx": () => import("./../../../src/pages/ampify-studio.tsx" /* webpackChunkName: "component---src-pages-ampify-studio-tsx" */),
  "component---src-pages-billing-info-tsx": () => import("./../../../src/pages/billing-info.tsx" /* webpackChunkName: "component---src-pages-billing-info-tsx" */),
  "component---src-pages-blocswave-tsx": () => import("./../../../src/pages/blocswave.tsx" /* webpackChunkName: "component---src-pages-blocswave-tsx" */),
  "component---src-pages-competition-tsx": () => import("./../../../src/pages/competition.tsx" /* webpackChunkName: "component---src-pages-competition-tsx" */),
  "component---src-pages-groovebox-tsx": () => import("./../../../src/pages/groovebox.tsx" /* webpackChunkName: "component---src-pages-groovebox-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-launchpad-tsx": () => import("./../../../src/pages/launchpad.tsx" /* webpackChunkName: "component---src-pages-launchpad-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-my-account-tsx": () => import("./../../../src/pages/my-account.tsx" /* webpackChunkName: "component---src-pages-my-account-tsx" */),
  "component---src-pages-ninjatune-legal-tsx": () => import("./../../../src/pages/ninjatune_legal.tsx" /* webpackChunkName: "component---src-pages-ninjatune-legal-tsx" */),
  "component---src-pages-player-tsx": () => import("./../../../src/pages/player.tsx" /* webpackChunkName: "component---src-pages-player-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy_policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-sale-tsx": () => import("./../../../src/pages/sale.tsx" /* webpackChunkName: "component---src-pages-sale-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-soundpacks-legal-tsx": () => import("./../../../src/pages/soundpacks_legal.tsx" /* webpackChunkName: "component---src-pages-soundpacks-legal-tsx" */),
  "component---src-pages-soundpacks-tsx": () => import("./../../../src/pages/soundpacks.tsx" /* webpackChunkName: "component---src-pages-soundpacks-tsx" */),
  "component---src-pages-studio-plans-tsx": () => import("./../../../src/pages/studio/plans.tsx" /* webpackChunkName: "component---src-pages-studio-plans-tsx" */),
  "component---src-pages-studio-register-success-tsx": () => import("./../../../src/pages/studio/register-success.tsx" /* webpackChunkName: "component---src-pages-studio-register-success-tsx" */),
  "component---src-pages-subscription-cancel-tsx": () => import("./../../../src/pages/subscription/cancel.tsx" /* webpackChunkName: "component---src-pages-subscription-cancel-tsx" */),
  "component---src-pages-subscription-coupon-tsx": () => import("./../../../src/pages/subscription/coupon.tsx" /* webpackChunkName: "component---src-pages-subscription-coupon-tsx" */),
  "component---src-pages-subscription-payment-tsx": () => import("./../../../src/pages/subscription/payment.tsx" /* webpackChunkName: "component---src-pages-subscription-payment-tsx" */),
  "component---src-pages-subscription-success-tsx": () => import("./../../../src/pages/subscription/success.tsx" /* webpackChunkName: "component---src-pages-subscription-success-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-plans-tsx": () => import("./../../../src/templates/plans.tsx" /* webpackChunkName: "component---src-templates-plans-tsx" */),
  "component---src-templates-the-hub-tsx": () => import("./../../../src/templates/the-hub.tsx" /* webpackChunkName: "component---src-templates-the-hub-tsx" */)
}

