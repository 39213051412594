import React, {ReactElement, ReactNode} from 'react';
import {CustomerPortalLayout} from '../components/CustomerPortalLayout';
import * as Layouts from '../constants/layouts';

export default (props: {
  children: ReactElement;
  pageContext: {layout: string};
}) => {
  const {children, pageContext} = props;
  if (pageContext.layout === Layouts.CUSTOMER_PORTAL) {
    return <CustomerPortalLayout>{children}</CustomerPortalLayout>;
  }

  return children;
};
