const Typography = {
  featureTitle: {
    desktop: {
      fontSize: '5em',
    },
    mobile: {
      fontSize: '3.5em',
    },
  },
  title: {
    desktop: {
      fontSize: '3.5em',
      lineHeight: 1.1,
    },
    mobile: {
      fontSize: '2.8em',
      lineHeight: 1.2,
    },
    color: '#111',
  },
  subtitle: {
    desktop: {
      fontSize: '1.5em',
      lineHeight: 1.5,
    },
    mobile: {
      fontSize: '1.4em',
      lineHeight: 1.5,
    },
  },
  heading: {
    desktop: {
      fontSize: '2.5rem',
      lineHeight: 1.5,
    },
    mobile: {
      fontSize: '2rem',
      lineHeight: 1.2,
    },
    legal: {
      fontSize: '2rem',
      lineHeight: 1.5,
    },
  },
  sectionHeader: {
    desktop: {
      fontSize: '2em',
      lineHeight: 1.5,
    },
    mobile: {
      fontSize: '2em',
      lineHeight: 1.5,
    },
  },
  body: {
    desktop: {
      fontSize: '1.3rem',
      lineHeight: 1.5,
    },
    mobile: {
      fontSize: '1.3rem',
      lineHeight: 1.5,
    },
    color: '#555',
  },
  button: {
    fontSize: '1.3rem',
  },
  quote: {
    fontSize: '0.9em',
  },
  form: {
    fontSize: '1em',
  },
  code: {
    fontSize: '0.8em',
  },
  legal: {
    fontSize: '1.1rem',
    lineHeight: '1.5rem',
  },
};

export default Typography;
