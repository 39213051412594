import React, {FunctionComponent, ReactElement} from 'react';
import {StaticQuery, graphql, navigate} from 'gatsby';
import {Wrapper, Content, Inner, Image} from './CustomerPortalLayout.styles';
import {GlobalStyles} from '../../theme/GlobalStyles';

interface CustomerPortalLayoutProps {
  children: ReactElement;
}

export const CustomerPortalLayout: FunctionComponent<CustomerPortalLayoutProps> = ({
  children,
}) => (
  <>
    <GlobalStyles />
    <Wrapper>
      <Content>
        <Inner>
          {React.cloneElement(children, {
            onRedirect: (url: string) => {
              window.location.assign(decodeURIComponent(url));
            },
          })}
        </Inner>
      </Content>
      <StaticQuery
        query={getImage}
        render={(data) => <Image fluid={data.image.childImageSharp.fluid} />}
      />
    </Wrapper>
  </>
);

const getImage = graphql`
  query getImageQuery {
    image: file(relativePath: {eq: "ampify-lola.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
