import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import Colours from '../../constants/colours';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 42% 58%;
  height: 100vh;

  @media (max-width: 64rem) {
    grid-template-columns: 60% 40%;
  }

  @media (max-width: 48rem) {
    grid-template-columns: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Inner = styled.div`
  width: 70%;

  @media (max-width: 48rem) {
    width: 90%;
  }
`;

export const Image = styled(BackgroundImage)`
  background-color: ${Colours.black};
`;
