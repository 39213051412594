import {useContext, useState} from 'react';
import store from 'store';
import {AuthContext} from './auth.provider';
import {AuthContextState, AuthCredentials} from './auth.types';
import {User, UserApi} from '@focusrite-novation/ampify-api';
import {key as storageKey, expiryKey} from '../../storage';
import {createAuthedApi, createApi} from '../../api';

const defaultUserValues: User = {
  name: '',
  email: '',
  firstName: '',
  lastName: '',
  plans: [],
  currency: 'GBP',
  country: 'GB',
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const useProvideAuth = (): AuthContextState => {
  const [attemptedFetch, setAttemptedFetch] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isSubscriber, setIsSubscriber] = useState<boolean>(false);
  const [user, setUser] = useState<User>(defaultUserValues);

  const refreshUserInfo = async (apiUrl: string): Promise<User> => {
    const userApi = createAuthedApi(UserApi, apiUrl);
    const userResponse = await userApi.getUser();
    const userData = userResponse.data;

    setUser(userData);
    setIsLoggedIn(true);
    setIsSubscriber(userData.plans.length > 2);

    return userData;
  };

  const getUserInfo = async (apiUrl: string) => {
    const isTokenExpired = () => {
      const expiryUNIX = store.get(expiryKey);

      if (!expiryUNIX) {
        return false;
      }

      const tokenExpiryDate = new Date(expiryUNIX);
      return tokenExpiryDate < new Date();
    };

    const tokens = store.get(storageKey);
    if (tokens && tokens.access_token && !isTokenExpired()) {
      try {
        const response = await refreshUserInfo(apiUrl);
        setAttemptedFetch(true);
        return response;
      } catch (error) {} // we do not need to handle this error, the user will redirected to log in
    }

    setAttemptedFetch(true);

    return null;
  };

  const isSubscribedToPlan = (planId: string) => {
    return isLoggedIn && user.plans.includes(planId);
  };

  const updateExpiryDate = (expiryLength: number) => {
    const sToMs = 1000;
    const expiresInMs = expiryLength * sToMs;
    const twoMinutes = 120000;
    const tokenExpiryDate = Date.now() + expiresInMs - twoMinutes;
    store.set(expiryKey, tokenExpiryDate);
  };

  const login = async (apiUrl: string, {email, password}: AuthCredentials) => {
    const userApi = createApi(UserApi, apiUrl);
    const response = await userApi.getAuthToken(
      'password',
      email,
      password,
      'ampify',
      'email profile openid'
    );

    store.set(storageKey, response.data);
    updateExpiryDate(response.data.expires_in);

    return getUserInfo(apiUrl);
  };

  const logout = () => {
    store.remove(storageKey);

    setUser(defaultUserValues);
    setIsLoggedIn(false);
    setIsSubscriber(false);
  };

  return {
    user,
    login,
    logout,
    isLoggedIn,
    isSubscriber,
    isSubscribedToPlan,
    getUserInfo,
    attemptedFetch,
  };
};
