const Colours = {
  transparent: '00000000',
  white: '#fff',
  black: '#333',
  red: '#F26152',
  green: '#27a085',
  grey: {
    100: '#F2F2F2',
    200: '#E7E7E7',
    300: '#DDDDDD',
    400: '#CCCCCC',
    500: '#AAAAAA',
    600: '#777777',
    700: '#4C4C4C',
    800: '#333333',
    900: '#121212',
  },
  blue: {
    500: '#2A85C7',
    600: '#1C6DA9',
    900: '#012B49',
  },
  yellow: '#F2A041',
  redBackground: '#B31750',
  registeredBlue: '#3E88DA',
  subscriberPurple: '#BC3EDA',
  freeOrange: '#A53030',
  modalBackground: [10, 12, 7, 0.5],
};

export default Colours;
